/* fix padding under menu after resize */
@media screen and (max-width: 767px) {
  body { padding-top: 80px; }
  #sidebar {
    margin-left: -17rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}
@media screen and (min-width:768px) and (max-width: 991px) {
  body { padding-top: 130px; }
}
@media screen and (min-width: 992px) {
  body { padding-top: 80px; }
}

body {
  background-color: rgba(246, 246, 246, 1) !important;
  padding-top: 75px !important;
}

small {
  font-size: xx-small !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn-primary {
  color: #fff !important;
  background-color: rgba(0, 49, 88, 1) !important;
  border-color: rgba(0, 49, 88, 1) !important;
  display: inline-block !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  text-align: center !important;
  text-decoration: none !important;
  user-select: none !important;
  border: 1px solid transparent !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-out, background-color 0.15s ease-out, border-color 0.15s ease-out, box-shadow 0.15s ease-out !important;
}

.page-content .nav-link, .page-content a {
  color:rgba(0, 49, 88, 1) !important;
}

.form-check-input:checked {
  background-color: rgba(0, 49, 88, 1) !important;
  border-color: rgba(0, 49, 88, 1) !important;
}

.vertical-nav {
  min-width: 17rem;
  width: 17rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 49, 88, 1);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.page-content {
  width: calc(100% - 17rem);
  margin-left: 17rem;
  transition: all 0.4s;
}

.page-footer {
  width: calc(100% - 17rem);
  margin-left: 17rem;
  transition: all 0.4s;
}

/* for toggle behavior */

#sidebar.active {
  margin-left: -17rem;
}

#content.active {
  width: 100%;
  margin: 0;
}